import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import loaderLight from '@images/loader-light.svg'
import useRecaptchaLoader from '@helpers/hooks/useRecaptchaLoader'
import {
  Error,
  Loader,
  SearchFormWrapper,
  SearchInput,
  SearchInputLabel,
  RecaptchaWrapper,
  PrimaryButton,
  ButtonWrapper,
} from './styles'

/* for silent recaptcha */
const SITE_KEY = process.env.GATSBY_RECAPTCHA_SITEKEY

function getOrderNumberBasedOnSearchParams(searchParams) {
  if (typeof searchParams.get !== 'function') {
    return null
  }

  const ordernumber = searchParams.get('ordernumber')
  if (ordernumber) {
    return ordernumber
  }
  return null
}

const SearchForm = ({
  orderListRef,
  setOrderId,
  setPhoneNumber,
  setZipCode,
  setAuthToken,
  error,
  loading,
  fetchData,
}) => {
  const recaptchaRef = useRef(null)
  /* state for recaptcha */
  const { isWindow, isScriptLoaded } = useRecaptchaLoader()

  const searchParams = new URLSearchParams(isWindow ? window.location.search : undefined)
  const initialOrderNumber = getOrderNumberBasedOnSearchParams(searchParams)

  /* for silent recaptcha */
  const verifyCallback = token => {
    setAuthToken(token)
  }

  useEffect(() => {
    if (initialOrderNumber) {
      setOrderId(initialOrderNumber)
    }
  }, [setOrderId, initialOrderNumber])

  return (
    <>
      <SearchFormWrapper className="grid-x cell small-12" ref={orderListRef}>
        {error && <Error>{error}</Error>}
        <SearchInputLabel htmlFor="order_status_number" className="cell small-12">
          <div>
            <span>*</span>Order Number
          </div>
          <SearchInput
            className="cell small-12"
            type="text"
            name="order_number"
            maxLength={20}
            id="order_status_number"
            onChange={e => setOrderId(e.target.value)}
            error="order"
            phrase="phone"
          />
        </SearchInputLabel>
        <SearchInputLabel htmlFor="tel" className="cell small-12">
          <div>
            <span>*</span>Phone Number
          </div>
          <SearchInput
            className="cell small-12"
            type="tel"
            name="order_phone_number"
            id="tel"
            autoComplete="tel"
            onChange={e => setPhoneNumber(e.target.value)}
            error={error}
            phrase="phone"
          />
        </SearchInputLabel>
        <SearchInputLabel htmlFor="zipcode" className="cell small-12">
          <div>
            <span>*</span>Delivery Zip Code
          </div>
          <SearchInput
            className="cell small-12"
            name="order_zip"
            type="text"
            maxLength={5}
            id="zipcode"
            autoComplete="postal-code"
            onChange={e => setZipCode(e.target.value)}
            error={error}
            phrase="Zip"
          />
        </SearchInputLabel>
        <ButtonWrapper>
          {isWindow && isScriptLoaded && (
            <RecaptchaWrapper ref={recaptchaRef} sitekey={SITE_KEY} render="explicit" verifyCallback={verifyCallback} />
          )}
          <PrimaryButton type="button" onClick={fetchData}>
            {!loading ? 'Find Order' : <Loader alt="loading matching orders" src={loaderLight} />}
          </PrimaryButton>
        </ButtonWrapper>
      </SearchFormWrapper>
    </>
  )
}

SearchForm.propTypes = {
  orderListRef: PropTypes.any,
  setOrderId: PropTypes.func,
  setPhoneNumber: PropTypes.func,
  setZipCode: PropTypes.func,
  setAuthToken: PropTypes.func,
  error: PropTypes.any,
  loading: PropTypes.any,
  fetchData: PropTypes.func,
}

export default SearchForm
