import React from 'react'
import { LegendWrapper, Legend, ReasonsForStatusCheck } from './styles'

const legendMessage = 'Some things you may be able to do in order status:'

const possibleReasons = [
  'Make a payment on your order',
  'View your delivery window timeframe',
  'Schedule or reschedule delivery for an eligible order',
]

const OrderSearchLegend = () => (
  <LegendWrapper>
    <p>{legendMessage}</p>
    <ReasonsForStatusCheck>
      {possibleReasons.map((reason, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={index}>{reason}</li>
      ))}
    </ReasonsForStatusCheck>
    <Legend>
      <span>*</span>
      <p> = Required Field</p>
    </Legend>
  </LegendWrapper>
)

export default OrderSearchLegend
