import React from 'react'
import { array, string } from 'prop-types'
import { useFirebaseContext } from '@context/firebaseContext'
import { styled } from '@mui/material'
import OrderListItem from '../order-list-item'

const OrderListWrapper = styled('div')(() => ({
  width: '100%',
  background: '#fff',
  paddingBottom: '1rem',
}))

const Title = styled('p')(({ theme }) => ({
  fontSize: '1.125rem',
  fontWeight: '600',
  width: '100%',
  borderColor: theme.palette.common.black,
  paddingBottom: '10px',
  [theme.breakpoints.down('md')]: {
    fontWeight: 'bold',
  },
}))

const TopHeadings = styled('div')(({ theme }) => ({
  backgroundColor: '#f5f8fb',
  padding: '8px 0',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

const Heading = styled('div')(({ theme }) => ({
  fontSize: '.75rem',
  textAlign: 'center',
  fontWeight: '600',
  whiteSpace: 'nowrap',
  [theme.breakpoints.up('md')]: {
    fontSize: '1rem',
    '&.large-2': {
      width: '14%',
      padding: '0 0.5rem',
    },
  },
}))

const OrderList = ({ orderList, heading }) => {
  const { user } = useFirebaseContext()
  return (
    <OrderListWrapper>
      {orderList && orderList.length > 0 && (
        <div className="grid-x cell small-12">
          <Title>{heading}</Title>
          <TopHeadings className="grid-x cell small-12">
            <Heading className="cell small-6 large-2">Order #</Heading>
            <Heading className="cell small-6 large-2">Order Date</Heading>
            <Heading className="cell small-6 large-2">Total</Heading>
            <Heading className="cell small-6 large-2">Balance</Heading>
            <Heading className="cell small-6 large-2">Delivery Date</Heading>
            <Heading className="cell small-6 large-2">Status</Heading>
            <Heading className="cell small-6 large-2" />
          </TopHeadings>
          <div className="grid-x cell small-12">
            {orderList.map((order, index) => (
              <OrderListItem order={order} key={order.OrderNumber + order.OrderDate} user={user} bgblue={index % 2} />
            ))}
          </div>
        </div>
      )}
    </OrderListWrapper>
  )
}

OrderList.propTypes = {
  heading: string,
  orderList: array,
}

export default OrderList
