import { styled } from '@mui/material'
import { breakPoints, fonts, colors } from '@constants/styles'
import Recaptcha from 'react-recaptcha'

export const OrderSearchWrapper = styled('div')({
  width: '100%',
})

export const RecaptchaWrapper = styled(Recaptcha)({
  width: '100%',
})

export const ResetButton = styled('button')({
  width: '100%',
})

export const OrderStatusWrapper = styled('section')({
  padding: '1rem',
})

export const Title = styled('h1')(({ theme }) => ({
  color: theme.palette.secondary.dark,
  lineHeight: 1,
  fontSize: '24px',
  fontWeight: 400,
  padding: 0,
  textAlign: 'left',
  marginBottom: '1rem',
  textTransform: 'uppercase',
  [theme.breakpoints.up('md')]: {
    fontSize: '32px',
  },
}))

export const Message = styled('p')({
  marginBottom: '1rem',
})

export const Loader = styled('img')({
  height: '1rem',
})

export const Error = styled('span')(({ theme }) => ({
  fontSize: '.75rem',
  color: theme.palette.error.main,
}))

// * * * * * Search Form * * * * *

export const SearchFormWrapper = styled('form')({
  display: 'flex',
})

export const ButtonWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
})

export const PrimaryButton = styled('button')(({ theme }) => ({
  cursor: 'pointer',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  textTransform: 'uppercase',
  borderRadius: 0,
  fontWeight: 600,
  margin: '.5rem 0',
  padding: '0.625rem 1.625rem',
  '&:focus': {
    textDecoration: 'underline',
  },
  '&&&': {
    height: '2.5rem',
    width: '9rem',
    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
    },
  },
}))

export const SearchInput = styled('input')(({ theme, error, phrase }) => ({
  '&&&': {
    width: '100%',
    maxWidth: '500px',
    borderColor: error && error.includes(phrase) ? theme.palette.error.main : '',
  },
}))

export const SearchInputLabel = styled('label')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  span: {
    color: theme.palette.error.main,
  },
}))

// * * * * * Order Search Legend * * * * *

export const LegendWrapper = styled('section')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '160px',
  justifyContent: 'space-between',
})

export const Legend = styled('section')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  fontSize: '1.5rem',
  span: {
    color: theme.palette.error.main,
  },
}))

export const ReasonsForStatusCheck = styled('ul')({
  margin: '0 0 10px',
  li: {
    listStyleType: 'disc',
    marginLeft: '20px',
  },
})

// * * * * * Order Search Help Text * * * * *

export const HelpTextWrapper = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    padding: '3rem',
  },
}))

export const BackgroundBox = styled('div')(({ theme }) => ({
  padding: '1rem 0',
  border: 'none',
  borderTop: `1px 0 ${theme.palette.secondary.dark}`,
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  h2: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  a: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    fontWeight: 600,
    '&:hover': {
      color: theme.palette.secondary.dark,
    },
  },
  [theme.breakpoints.up('sm')]: {
    border: 'none',
    borderLeft: `1px solid ${theme.palette.secondary.dark}`,
  },
}))
