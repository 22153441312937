import React from 'react'
import { useDispatch } from 'react-redux'
import { shape, string, bool, any } from 'prop-types'
import { navigate } from 'gatsby'
import { getDateCalendarFormat } from '@helpers/date'
import { currencyFormatUS } from '@helpers/string-helper'
import { setOrder, setOrderNumber } from '@redux/modules/orderStatus'
import { styled, Box } from '@mui/material'
import { orderStatusEvent } from '@helpers/google-tag-manager'
import { navToMakePayment, navToScheduleDelivery } from '../order-payment/helpers'

const OrderGroup = styled(Box)(({ theme, bgblue }) => ({
  fontSize: '.75rem',
  marginBottom: 0,
  display: 'flex',
  minHeight: '2.75rem',
  [theme.breakpoints.up('md')]: {
    padding: 0,
    alignItems: 'center',
    fontSize: '1rem',
    backgroundColor: bgblue ? '#f5f8fb' : 'transparent',
  },
}))

const DesktopView = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    '.cell': {
      margin: 'auto 0',
    },
  },
}))

const MobileView = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    '.cell': {
      marginBottom: '1rem',
    },
    hr: {
      borderTop: `1px solid ${theme.palette.grey[500]}`,
    },
  },
}))

const MobileHeading = styled('div')(() => ({
  fontSize: '.75rem',
  color: '#757575',
}))

const OrderHeadingWrapper = styled('div')(() => ({
  width: '100%',
  backgroundColor: '#f5f8fb',
  display: 'flex',
  padding: '8px',
}))

const OrderDataWrapper = styled('div')(() => ({
  padding: '8px',
}))

const OrderValue = styled('div')(({ theme }) => ({
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    '&.large-2': {
      width: '14%',
    },
  },
}))

const MobileOrderValue = styled('div')(() => ({
  fontSize: '1rem',
}))

const ButtonWrapper = styled('div')(() => ({
  '& button': {
    minWidth: 'unset !important',
    width: '100% !important',
    maxWidth: 'unset !important',
  },
}))

const OrderDetailsLink = styled('button')(() => ({
  fontSize: '1rem',
  textDecoration: 'underline',
  color: '#0053a0',
}))

const PrimaryButton = styled('button')(({ theme }) => ({
  borderRadius: '5px',
  border: '2px solid',
  boxSizing: 'border-box',
  fontSize: '.875rem',
  fontWeight: '600',
  minWidth: '160px',
  maxWidth: '365px',
  margin: '0.5rem 0',
  padding: '10px 15px',
  textAlign: 'center',
  textTransform: 'capitalize',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.light,
  borderColor: theme.palette.primary.main,
  [theme.breakpoints.down('md')]: {
    maxWidth: '300px',
    display: 'block',
    margin: '0 auto 20px auto',
    width: '100%',
  },
}))

const RedButton = styled(PrimaryButton)(({ theme }) => ({
  backgroundColor: theme.palette.error.border,
  borderColor: theme.palette.error.border,
}))

const OrderListItem = React.memo(({ order, user, bgblue }) => {
  const dispatch = useDispatch()

  const {
    Balance = '0',
    DeliveryDate = 'someString',
    OrderDate = '0',
    OrderNumber = '0',
    OrderTotal = '0',
    Status = 'someString',
  } = order

  const viewOrderDetails = () => {
    dispatch(setOrder(order))
    dispatch(setOrderNumber(OrderNumber))
    orderStatusEvent('viewOrderDetails', order)
    // TODO: navigation need to add code to track ga data
    navigate(user ? '/account/order/details' : '/order/details')
  }

  const orderDateFormat = getDateCalendarFormat(OrderDate)
  const OrderTotalFormat = currencyFormatUS(OrderTotal)
  const BalanceFormat = currencyFormatUS(Balance)
  const hasBalance = order?.Balance > 0
  const DeliveryDateFormat = order.DeliveryDate ? getDateCalendarFormat(DeliveryDate) : 'Unscheduled'
  const renderStatus = () => (hasBalance ? 'Balance Due' : Status)

  const renderDetailsButton = () => {
    const isExternalDelivery = ['O', 'U', 'T'].includes(order?.DeliveryType)
    const isNotScheduledOrExternal = !order?.DeliveryDate && !isExternalDelivery
    const shouldDisplayScheduleButton =
      isNotScheduledOrExternal && !hasBalance && Status?.toLowerCase?.() !== 'invoiced'

    if (hasBalance) {
      return (
        <RedButton type="button" onClick={() => navToMakePayment(user?.userId, order)}>
          MAKE PAYMENT
        </RedButton>
      )
    }
    if (shouldDisplayScheduleButton) {
      return (
        <PrimaryButton
          style={{ padding: '10px 15px' }}
          type="button"
          onClick={() => navToScheduleDelivery(user?.userId, order.orderKey)}
        >
          SCHEDULE DELIVERY
        </PrimaryButton>
      )
    }
    return ''
  }

  return (
    <>
      {order && (
        <>
          <OrderGroup className="large-12 small-12" key={OrderNumber} bgblue={bgblue}>
            <DesktopView className="grid-x cell large-12">
              <OrderValue className="cell small-6 large-2">
                <OrderDetailsLink onClick={viewOrderDetails}>{OrderNumber}</OrderDetailsLink>
              </OrderValue>
              <OrderValue className="cell small-6 large-2"> {orderDateFormat}</OrderValue>
              <OrderValue className="cell small-6 large-2"> {OrderTotalFormat}</OrderValue>
              <OrderValue className="cell small-6 large-2"> {BalanceFormat}</OrderValue>
              <OrderValue className="cell small-6 large-2">
                {order.DeliveryDate === null ? 'N/A' : DeliveryDateFormat}
              </OrderValue>
              <OrderValue className="cell small-6 large-2"> {renderStatus()}</OrderValue>
              <OrderValue className="cell small-12 large-2">
                <ButtonWrapper>{renderDetailsButton()}</ButtonWrapper>
              </OrderValue>
            </DesktopView>
            <MobileView className="grid-x cell small-12">
              <OrderHeadingWrapper className="small-16">
                <MobileOrderValue style={{ marginRight: '8px' }}>Order #</MobileOrderValue>
                <OrderDetailsLink onClick={viewOrderDetails}>{OrderNumber}</OrderDetailsLink>
              </OrderHeadingWrapper>
              <OrderDataWrapper className="grid-x">
                <div className="grid-x cell small-12">
                  <div className="small-4">
                    <MobileHeading>Order Date</MobileHeading>
                    <MobileOrderValue>{orderDateFormat}</MobileOrderValue>
                  </div>
                  <div className="small-4">
                    <MobileHeading>Total</MobileHeading>
                    <MobileOrderValue>{OrderTotalFormat}</MobileOrderValue>
                  </div>
                  <div className="small-4">
                    <MobileHeading>Balance</MobileHeading>
                    <MobileOrderValue>{BalanceFormat}</MobileOrderValue>
                  </div>
                </div>
                <div className="grid-x cell small-12">
                  <div className="small-4">
                    <MobileHeading>Delivery Date</MobileHeading>
                    <MobileOrderValue>{order.DeliveryDate === null ? 'N/A' : DeliveryDateFormat}</MobileOrderValue>
                  </div>
                  <div className="small-4">
                    <MobileHeading>Status</MobileHeading>
                    <MobileOrderValue>{renderStatus()}</MobileOrderValue>
                  </div>
                </div>
              </OrderDataWrapper>
              <OrderValue className="small-12 large-2">{renderDetailsButton()}</OrderValue>
            </MobileView>
          </OrderGroup>
        </>
      )}
    </>
  )
})

OrderListItem.displayName = 'OrderListItem'

OrderListItem.propTypes = {
  order: shape({
    Balance: string,
    DeliveryDate: string,
    OrderDate: string,
    OrderNumber: string,
    OrderTotal: string,
    Status: string,
  }).isRequired,
  user: any,
  bgblue: bool,
}

export default OrderListItem
